@import '../../ui/colors.scss';
@import '../../ui/media.scss';
@import '../../ui/variables.scss';

$header-height: 50px;
$logo-width: 42px;

#header {

    position: absolute;
    width: 100%;
    text-align: right;
    z-index: 100;

    section > div {
        max-width: $desktop;
        margin: 0 auto;
        padding: 16px 0 0 0;
    }

    .logo-link {
        position: absolute;
        top: 10px;
        left: 10px;
        display: block;
        width: $logo-width;
        height: $logo-width;
        
        svg {
            width: $logo-width;
            height: $logo-width;
            stroke-width: 2px;
        }
    }

    // circle {
    //     fill: white;
    // }

    // .hamburger {
    //     left: auto;
    //     right: -6px;
    //     width: $logo-width;
    //     height: $logo-width;
    //     padding: 10px 5px 0 5px;

    //     @include tablet {
    //         display: none;
    //     }
        
    //     div {
    //         height: 3px;
    //         background-color: $black;
    //         top: -4px;
    //         position: relative;
    //         border-radius: 1px;

    //         &:nth-child(2) {
    //             top: 0;
    //         }

    //         &:nth-child(3) {
    //             top: 4px;
    //         }
    //     }
    // }

    // ul {
    //     margin: 0 -20px 0 0;
    //     display: none;

    //     @include tablet {
    //         display: block;
    //     }
    // }

    a {
        // height: $header-height;
        // line-height: $header-height;
        // vertical-align: middle;
        // display: inline-block;
        font-weight: 700;
        text-transform: uppercase;
        // text-decoration: none;
        // padding: 4px 20px 0 20px;
        color: $black;

        &:hover,
        &:active,
        &:focus {
            color: $black;
        }
    }
    
    
    li {
        // height: $header-height;
        display: inline-block;
        // vertical-align: top;
    }


}




// .mobile-nav {
//     position: fixed;
//     width: 100vw;
//     height: 100vh;

//     .backdrop {
//         transform-origin: top right;
//         background-color: $black;
//         transition: transform 250ms ease, opacity 200ms ease 40ms;
//         transform: scale(0) translate(125vw, -125vh);
//         width: 250vw;
//         height: 250vh;
//         position: absolute;
//         border-radius: 50%;
//         right: 0;
//         top: 0;
//         pointer-events: none;

//         width: 100vw;
//         height: 100vh;
//         transform: translate(100vw, 0);
//         border-radius: 0;
//         transform-origin: top left;
//         opacity: 0;


//     }

//     button {
//         width: 50px;
//         height: 50px;
//     }
// }

// .mobile-menu-open {

//     .mobile-nav {
//         .backdrop {
//             transform: scale(1) translate(100vw, -100vh) rotate(0);
//             transform: translate(30vw, 0);
//             opacity: 1;
//         }
//     }
// }