@import '../media.scss';
@import '../colors.scss';
@import '../variables.scss';

$height: 30px;
$sign-width: 50px;

.interaction-slider {

    position: relative;
    $height: 30px;
    padding: 18px 0 0 0;

    .slider-wrapper {
        position: relative;
    }

    .slider-track {
        position: absolute;
        width: calc(100% - #{2 * $sign-width});
        height: 2px;
        top: 14px;
        background-color: $grey-bg-light;
        left: 0;
        pointer-events: none;
        margin: 0 0 0 $sign-width;
    }

    .slider-track-focus,
    .slider-track-handle,
    .slider-track-overlay {
        position: absolute;
        top: 2px;
    }

    .placeholder {
        top: 10px;
        transition: color 600ms ease, transform 300ms ease;
        transform: scale(1) translateY(0);
        transform-origin: left top;
        font-weight: 500;
        position: absolute;
        left: 0;
        pointer-events: none;
        color: $grey-text;
    }

    .value-placeholder {
        pointer-events: none;
        visibility: hidden;
    }

    &.has-hint {

        .placeholder {
            transform: scale(1) translateY(-18px);
            color: $black;
        }
    }

    &.has-value,
    &.has-focus {

        .placeholder {
            transform: scale(0.88) translateY(-18px);
        }
    }

    &.has-value {

        .placeholder {
            color: $grey-text;
        }
    }

    &.has-focus {

        .placeholder {
            color: $blue-text-dark;
        }
    }

    .slider-track-overlay {
        background-color: $blue-text-dark;
        height: 2px;
        left: 0;
        top: 0;
        z-index: 10;
        transition: width 300ms ease;
    }

    .slider-track-handle {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        top: -5px;
        background-color: $blue-text-dark;
        z-index: 12;
        margin: 0 0 0 -6px;
        transition: left 300ms ease;
    }
    
    input {
        height: $height;
        width: calc(100% - #{2 * $sign-width});
        opacity: 0.5;
        opacity: 0;
        margin: 0 0 0 $sign-width;
        cursor: pointer;
    }

    input,
    label {
        vertical-align: top;
    }

    .slider-track-focus {
        transition: transform 300ms ease, left 300ms ease;
        background-color: $blue-text-dark;
        opacity: 0.15;
        border-radius: 20px;
        height: 40px;
        width: 40px;
        margin: -21px 0 0 -20px;
        transform: scale(0.1);
        z-index: 99;
    }

    &:hover {
        .slider-track-focus {
            transform: scale(0.8);
        }
    }

    &.has-focus {
        .slider-track-focus {
            transform: scale(0.8);
        }
    }

    &.is-active {
        .slider-track-focus {
            transform: scale(1);
        }
    }

    .hint {
        font-size: $small;
        line-height: 1.4;
        color: $grey-text;
    }

    .value {
        font-weight: 700;
        font-size: $small;
        line-height: 1.4;
        margin: 0 0 14px 0;
        position: relative;

        @include tablet {
            font-size: $regular;
        }
    }

    .opacity-switch {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .labels-placeholder {
        pointer-events: none;
        font-size: $small;
        line-height: $small;
        visibility: hidden;
    }


    .decrement-wrapper,
    .increment-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
        transform-style: preserve-3d;

        &.active {
            .sign {
                box-shadow: $shadow;
            }
        }
    }

    .increment-wrapper {
        left: auto;
        right: 0;
    }

    .null-sign,
    .error-sign,
    .success-sign,
    .disabled-sign,
    .minus-sign,
    .plus-sign,
    .edit-sign,
    .clear-sign {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
    }

    .null-sign {
        
        .sign {
            background-color: $white;
        }

        .icon-wrapper {
            visibility: hidden;
        }
    }
}