@import '../colors.scss';
@import '../variables.scss';

.ordinal-slider {

    .input-statuses {

        position: relative;
        width: 100%;
        margin: 8px 0 0 0;

        .label {
            width: 50%;
            padding: 0 8px 0 0;
            font-size: $micro;
            font-size: $small;
            line-height: $small;
            position: absolute;
            top: 0;
            left: 0;
            transition: color 300ms ease;
            font-weight: 500;
        }

        .label-last {
            padding: 0 0 0 8px;
            text-align: right;
            left: auto;
            right: 0;
        }

        .labels-placeholder {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding: 0 10px 0 0;
        }
    }

    .hint {
        position: absolute;
        transition: opacity 300ms ease;
        font-style: italic;
    }

    &.has-value {

        .input-statuses {

            .label {
                color: $grey-text;
                color: $grey6;
                color: #a3a7b5;
            }
        }

        .hint {
            opacity: 0;
        }


    }

    .indicator {
        height: 8px;
        width: 8px;
        border-radius: 4px;
        position: absolute;
        top: -3px;
        background-color: $grey-bg-light;
        display: inline-block;
        transition: background-color 300ms ease 150ms;

        &.active {
            background-color: $blue-text-dark;
        }
    }

    &.items-2 {
        .indicator-0 {
            left: 0;
        }

        .indicator-1 {
            right: 0;
        }
    }

    &.items-3 {
        .indicator-0 {
            left: 0;
        }

        .indicator-1 {
            left: 50%;
            margin: 0 0 0 -4px;
        }

        .indicator-2 {
            right: 0;
        }
    }

    &.items-4 {
        .indicator-0 {
            left: 0;
        }

        .indicator-1 {
            left: 33.33%;
            margin: 0 0 0 -4px;
        }

        .indicator-2 {
            left: 66.66%;
            margin: 0 0 0 -4px;
        }

        .indicator-3 {
            right: 0;
        }
    }

    &.items-5 {
        .indicator-0 {
            left: 0;
        }

        .indicator-1 {
            left: 25%;
            margin: 0 0 0 -4px;
        }

        .indicator-2 {
            left: 50%;
            margin: 0 0 0 -4px;
        }

        .indicator-3 {
            left: 75%;
            margin: 0 0 0 -4px;
        }

        .indicator-4 {
            right: 0;
        }
    }
    &.items-6 {
        .indicator-0 {
            left: 0;
        }

        .indicator-1 {
            left: 20%;
            margin: 0 0 0 -4px;
        }

        .indicator-2 {
            left: 40%;
            margin: 0 0 0 -4px;
        }

        .indicator-3 {
            left: 60%;
            margin: 0 0 0 -4px;
        }

        .indicator-4 {
            left: 80%;
            margin: 0 0 0 -4px;
        }

        .indicator-5 {
            right: 0;
        }
    }

}
