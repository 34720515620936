@import './colors.scss';
@import './variables.scss';
@import './media.scss';

/* bai-jamjuree-200 - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: normal;
font-weight: 200;
src: local('Bai Jamjuree ExtraLight'), local('BaiJamjuree-ExtraLight'),
        url('/fonts/bai-jamjuree-v1-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-200italic - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: italic;
font-weight: 200;
src: local('Bai Jamjuree ExtraLight Italic'), local('BaiJamjuree-ExtraLightItalic'),
        url('/fonts/bai-jamjuree-v1-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-300 - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: normal;
font-weight: 300;
src: local('Bai Jamjuree Light'), local('BaiJamjuree-Light'),
        url('/fonts/bai-jamjuree-v1-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-300italic - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: italic;
font-weight: 300;
src: local('Bai Jamjuree Light Italic'), local('BaiJamjuree-LightItalic'),
        url('/fonts/bai-jamjuree-v1-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-italic - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: italic;
font-weight: 400;
src: local('Bai Jamjuree Italic'), local('BaiJamjuree-Italic'),
        url('/fonts/bai-jamjuree-v1-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-regular - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: normal;
font-weight: 400;
src: local('Bai Jamjuree Regular'), local('BaiJamjuree-Regular'),
        url('/fonts/bai-jamjuree-v1-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-500 - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: normal;
font-weight: 500;
src: local('Bai Jamjuree Medium'), local('BaiJamjuree-Medium'),
        url('/fonts/bai-jamjuree-v1-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-500italic - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: italic;
font-weight: 500;
src: local('Bai Jamjuree Medium Italic'), local('BaiJamjuree-MediumItalic'),
        url('/fonts/bai-jamjuree-v1-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-600 - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: normal;
font-weight: 600;
src: local('Bai Jamjuree SemiBold'), local('BaiJamjuree-SemiBold'),
        url('/fonts/bai-jamjuree-v1-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-600italic - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: italic;
font-weight: 600;
src: local('Bai Jamjuree SemiBold Italic'), local('BaiJamjuree-SemiBoldItalic'),
        url('/fonts/bai-jamjuree-v1-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-700 - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: normal;
font-weight: 700;
src: local('Bai Jamjuree Bold'), local('BaiJamjuree-Bold'),
        url('/fonts/bai-jamjuree-v1-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* bai-jamjuree-700italic - latin */
@font-face {
font-family: 'Bai Jamjuree';
font-style: italic;
font-weight: 700;
src: local('Bai Jamjuree Bold Italic'), local('BaiJamjuree-BoldItalic'),
        url('/fonts/bai-jamjuree-v1-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/bai-jamjuree-v1-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lora-italic - latin */
@font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 400;
    src: local('Lora Italic'), local('Lora-Italic'),
         url('/fonts/lora-v12-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/fonts/lora-v12-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

  /* lora-700italic - latin */
@font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 700;
    src: local('Lora Bold Italic'), local('Lora-BoldItalic'),
         url('/fonts/lora-v12-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/fonts/lora-v12-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'lovelo';
    src: url('/fonts/lovelo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'odachi';
    src: url('/fonts/odachi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* playfair-display-italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 400;
    src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'),
         url('/fonts/playfair-display-v13-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/fonts/playfair-display-v13-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* playfair-display-700italic - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 700;
    src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'),
         url('/fonts/playfair-display-v13-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/fonts/playfair-display-v13-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* playfair-display-900italic - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 900;
    src: local('Playfair Display Black Italic'), local('PlayfairDisplay-BlackItalic'),
         url('/fonts/playfair-display-v13-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('/fonts/playfair-display-v13-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

.lovelo { font-family: 'lovelo'; }
.odachi { font-family: 'odachi'; }

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
    font-weight: 700;
    margin: 30px 0 14px 0;
    // font-family: 'Playfair Display';
    line-height: 1.4;
    
    &:first-child {
        margin: 0 0 14px 0;
    }
}

h1 {
    font-size: 24px;

    @include tablet {
        font-size: 32px;
    }
}

h2 {
    font-size: 24px;

    @include tablet {
        font-size: 26px;
    }
}

p,
body {
    line-height: $line-height;
    font-family: 'Bai Jamjuree';
    font-weight: 400;
    font-size: $regular;
    color: $black;
}

p {
    margin: 14px 0;

    &:first-child {
        margin: 0 0 14px 0;
    }
}

h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p {
    margin: 10px 0 14px 0;
}


i {
    // font-family: 'Lora', serif;
    // font-family: 'Playfair Display', serif;
    font-family: 'Bai Jamjuree';
    font-weight: 400;
}

strong i {
    font-weight: 700;
}