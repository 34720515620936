@import '../variables.scss';
@import '../colors.scss';

$width: 40px;


.interaction-text {
    position: relative;
    margin: 12px 0 0 0;

    .placeholder,
    .hint {
        position: absolute;
        top: 11px;
        left: 0;
        pointer-events: none;
        color: $grey-text;
    }
    
    .hint {
        transition: opacity 300ms ease;
        opacity: 0;
    }

    .placeholder {
        top: 10px;
        transition: color 600ms ease, transform 300ms ease;
        transform: scale(1) translateY(0);
        transform-origin: left top;
        font-weight: 500;
    }

    .hint,
    .placeholder,
    .sign-wrapper,
    input {
        display: inline-block;
        width: calc(100% - #{$width});
        vertical-align: top;
        height: $input-height;
        line-height: #{$input-height - 11px};
        font-size: $regular;
    }
    
    input {
        border-bottom: 1px solid $grey-bg;
        padding: 10px 0 0 0;
        color: $black;
        transition: color 300ms ease;
        font-family: 'Bai Jamjuree';

        &:focus,
        &:active {
            outline: 0;
        }
    }

    &.is-loading,
    &.is-disabled {
        pointer-events: none;
        
        .placeholder,
        input {
            color: $disabled-text;
        }
    }

    &.has-focus,
    &.has-value {

        .placeholder {
            transform: scale(0.88) translateY(-28px);
        }
    }
    
    &.has-focus {
        
        .placeholder {
            color: $blue-text-dark;
        }

        .hint {
            opacity: 1;
        }
    }

    &.has-value {
        
        .hint {
            opacity: 0;
        }
    }

    &.has-success {

        &.has-focus {

            .placeholder {
                color: $green-text;
            }
        }

    }

    &.has-error {

        &.has-focus {

            .placeholder {
                color: $red-text;
            }
        }
    }

    &.is-animating {
        .sign-wrapper {
            pointer-events: none;
        }
    }

    .text-flipper {
        transform-style: preserve-3d;
        position: absolute;
        right: 0;
        top: 2px;
        width: 32px;
        height: 32px;
    }

    .sign-wrapper {
        width: $width;
        position: relative;
    }

    .pending-sign,
    .error-sign,
    .success-sign,
    .disabled-sign,
    .edit-sign,
    .clear-sign {
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
    }
}