.photos {

    padding: 70px 0;

    .drop {
        height: 1000px;
        opacity: 0.5;
        background: linear-gradient(#000 10%, transparent 70%);
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
    }

    .bg {
        background: url('/images/sunset.jpg');
        height: 1000px;
        background-position: center bottom;
        background-size: cover;
        // filter: grayscale(100%);
    }
     
}