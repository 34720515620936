@import '../../ui/media.scss';
@import '../../ui/colors.scss';
@import '../../ui/variables.scss';


// eaf6f0

.home {

    .top {

        background: radial-gradient(#ffffff, #cdeadc);

        @include desktop {
            background: none;
        }

        section {
            border-bottom: 1px solid #bdd8cb;

            @include desktop {
                border-bottom: none;
            }
        }

        article {
            padding: 100px 0 80px 0;
            
            @include tablet {
                padding: 80px 0;
            }
            
            @include desktop {
                padding: 100px 0;
                padding: 60px 0 20px 0;
            }

        }
        
    }

    .edge,
    .mediocrity {
        position: relative;
        display: inline-block;
        line-height: 1.2;
    }

    .edge {
        i {
            left: -57px;

            @include tablet {
                left: -60px;
            }

            @include desktop {
                left: -84px;
            }
        }
    }

    .mediocrity {
        i {
            left: -24px;

            @include tablet {
                left: -28px;
            }

            @include desktop {
                left: -36px;
            }
        }
    }
    
    .top-header {
        text-align: center;

        i {
            font-weight: normal;
            font-size: 20px;
            font-family: 'Playfair Display';
            letter-spacing: 0;
            position: absolute;
            top: 16px;

            @include landscape {
                top: 26px;
            }

            @include tablet {
                top: 26px;
            }

            @include desktop {
                top: 40px;
                font-size: 30px;
            }
        }

        span {
            font-family: 'Lovelo';
            letter-spacing: -0.25rem;
            font-size: 48px;
            text-shadow: 0 10px 20px #718683;

            @include landscape {
                font-size: 66px;
                letter-spacing: -0.4rem;
            }
            
            @include tablet {
                text-shadow: 0 15px 40px #718683;
                font-size: 66px;
                letter-spacing: -0.4rem;
            }

            @include desktop {
                font-size: 94px;
                letter-spacing: -0.55rem;
                text-shadow: 0 15px 40px #7D8D9D;
            }
        }
    }

}