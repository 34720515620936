@import '../../ui/media.scss';
@import '../../ui/colors.scss';
@import '../../ui/variables.scss';


.home {

    .anim-parent {
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 10;
    }
    
    .anim-child {
        width: 500%;
        transition: transform 1s ease;

        @include tablet {
            transition: transform 2s ease;
        }

        &.disable-anim {
            transition: none;
        }
    }

    .quotes {

        margin: 0 -16px;

        @include landscape {
            margin: 0 -25px;
        }

        @include tablet {
            margin: 0;
        }
    }
    
    .quote-wrapper {

        position: relative;
        line-height: 1.5;
        padding: 40px 0;
        min-height: 250px;

        @include tablet {
            padding: 80px 0;
            min-height: 325px;
        }

        .bg {
            color: #f2f2f2;
            z-index: 1;
            font-family: 'Playfair display';
            position: relative;
            margin: 0 25px;
            
            @include tablet {
                max-width: $desktop;
                margin: 0 auto;
            }
            
            span {
                font-size: 400px;
                line-height: 300px;
                position: absolute;
                top: 0;
                right: 0;

                @include tablet {
                    font-size: 700px;
                    line-height: 400px;
                }
            }
            
        }

        .anim-item {
            position: relative;
            z-index: 2;
            width: 20%;
            display: inline-block;
            vertical-align: top;
            transition: opacity 900ms ease;
            padding: 0 16px;

            @include landscape {
                padding: 0 25px;
            }

            @include tablet {
                padding: 0;
            }
        }

        figure {
            max-width: $desktop;
            margin: 0 auto;
        }
        
        blockquote {
            margin: 0 0 16px 0;
            font-family: 'Playfair display';
            line-height: 1.2;
            font-size: 30px;

            @include tablet {
                font-size: 50px;
            }
        }

        cite {
            font-size: $regular;
            color: #888;
            font-style: normal;
        }
    }
}
