@import './ui/typography.scss';
@import './ui/colors.scss';
@import './ui/media.scss';

$footer-height: 300px;
$logo-width: 42px;




.app {
    margin: 0;
    border-bottom: none;
    position: relative;
    min-height: 110vh;
    padding: 0 0 $footer-height 0;
    overflow: hidden;
}

#curtain {
    z-index: 500;
}

.initialized #curtain {
    opacity: 0;
}

.mobile-menu-open #curtain {
    pointer-events: all;
    opacity: 0.4;
}

.page-wrapper {
    z-index: 400;
}

.backdrop {
    z-index: 600;
}

.backdrop-bg {
    background-color: $black;
    // transition: all 300ms ease;
    // transition: transform 300ms ease;
    position: absolute;
    // top: 0;
    // right: 16px;
    // top: 20px;
    // top: 0;
    // right: 0;
    // width: 30px;
    // height: 30px;
    // transform: translate(-10px, 34px) scale(1, 1) rotate(45deg);

    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    // transform: rotate(45deg);
    transform-origin: center;
}

.backdrop {
    position: relative;

    .menu-link{
        position: absolute;
        top: 10px;
        display: block;
        width: $logo-width;
        height: $logo-width;
        right: 10px;

        @include tablet {
            display: none;
        }
        
        svg {
            width: $logo-width;
            height: $logo-width;
        }
    }

}

.mobile-menu-open {
    .backdrop-bg {
        // right: 0;
        // top: 0;
        // width: 100vw;
        // height: 120vh;
        // transform: rotate(0deg);
        // transform: translate(0, 0) scale(11, 18) rotate(0deg);
        // opacity: 1;
    }
}

footer {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $white;
    width: 100%;
    background-color: #fff;
    
    svg {
        width: 100%;
        height: $footer-height;
        display: block;
    }
}

.faded {
    background: linear-gradient($grey-bg-light, $grey-bg-dark);
}

.divider {
    stroke-width: 3px;
    fill: $white;
    fill-opacity: 0.2;
    stroke: $white;
}

section {
    padding: 0 16px;

    @include landscape {
        padding: 0 25px;
    }
    
    article {
        max-width: $desktop;
        margin: 0 auto;
        padding: 40px 0;
        
        @include tablet {
            padding: 60px 0;
        }

    }
}

.toned {
    background-color: #fbfbfb;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}




