$white: #fff;
$black: #47525e;
$grey-bg-light: #e9f0fb;
$grey-bg: #dfe7f1;
$grey-bg-dark: #dae3f1;

$blue-text-light: #65c3fd;
$blue-text-dark: #5684cf;
$blue-bg-dark: #4878c0;
$blue-bg: #5282c9;
$blue-bg-light: #68befe;
$blue-bg-light: #7ca8ef;

$green-bg: #50e3c2;
$red-bg: #ff9898;

$green-text: darken($green-bg, 16%);
$red-text: darken($red-bg, 10%);
$grey-text: #a4b1c1;
$disabled-text: lighten($grey-text, 10%);
$grey-text: #909ca8;

// bg: #fafafa;
// border: #ebebeb;

$grey1: #374750;
$grey2: #445a64;
$grey3: #546e7a;
$grey4: #607d8b;
$grey5: #78909c;
$grey6: #90a4ae;
$grey7: #b0bec5;
$grey8: #cfd8dc;