@import '../colors.scss';
@import '../media.scss';
@import '../variables.scss';


$sign-width: 32px;
$checkbox-width: 20px;

.checkbox {

    position: relative;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 0 0 0 40px;
    width: 100%;
    cursor: pointer;

    .fake-button {
        position: absolute;
        top: 0;
        left: 0;
    }

    input,
    .sign-wrapper {
        position: absolute;
        width: $sign-width;
        height: $sign-width;
        top: 0;
        left: 0;
        border-radius: #{$sign-width / 2};
        vertical-align: middle;
    }

    input {
        cursor: pointer;
        opacity: 0;
        z-index: 15;
    }

    .checkbox-label {
        line-height: $line-height-small;
        display: inline-block;
        font-size: $small;
        vertical-align: top;

        @include desktop {
            font-size: $regular;
        }
    }

    .checkbox-flipper {
        transform-style: preserve-3d;
        position: absolute;
        left: 0;
        width: $sign-width;
        height: 100%;
    }

    .icon-wrapper {
        width: $checkbox-width;
        height: $checkbox-width;
    }

    .fake-button {
        backface-visibility: hidden;
    }
    
    .sign {
        padding: 6px;
    }

    .sign-wrapper {
        top: -4px;
    }

    &.has-focus {
        .sign {
            box-shadow: $shadow;
        }
    }

}