@import '../../ui/media.scss';
@import '../../ui/colors.scss';
@import '../../ui/variables.scss';


.resume {

    font-size: $small;

    @include tablet {
        font-size: $regular;
    }

    .headline {
        height: 1px;
        background-color: $black;
        text-align: center;
        margin: 0 0 30px 0;
        background: linear-gradient(90deg, transparent 0%, #47525e 20%, #47525e 80%, transparent 100%);

        h3 {
            background-color: $white;
            padding: 0 10px;
            position: relative;
            top: -14px;
            display: inline-block;
            font-size: $small;
            font-style: italic;
            font-family: 'Playfair display';
        }
    }


    dl {
        font-size: $small;
        position: relative;
        z-index: 10;

        @include tablet {
            font-size: $regular;
        }
    }

    dt {
        width: 140px;

    }

    dd {
        width: calc(100% - 140px);
    }

    .love {
        font-weight: 600;
        // padding: 0 0 0 4px;
        text-align: right;
        
        span {
            width: 120px;
            text-align: left;
            display: inline-block;
        }
        // @include tablet {
        //     padding: 0 0 0 64px;
        // }

    }
}

.resume-skills,
.resume-exp {
    margin: 40px 0 60px 0;

    @include tablet {
        display: inline-block;
        vertical-align: top;
    }
}

.resume-skills {
    margin: 40px 0 80px 0;
    position: relative;


    @include tablet {
        width: 320px;
        padding: 0 30px 0 0;
    }

    @include desktop {
        width: 380px;
        padding: 0 80px 0 0;
    }
    
    dl {

        margin: 0 auto 80px auto;
        
        @include landscape {
            max-width: 320px;
            margin: 0 auto 100px auto;
        }
    }
    
    ul {
        text-align: right;
    }

    li {
        display: inline-block;
        background-color: $grey-bg-dark;
        background-color: #eaeaea;
        border: 1px solid #bdc4d0;
        width: 8px;
        height: 8px;
        margin: 0 0 0 4px;
        border-radius: 4px;

        &.active {
            background-color: $black;
            border: 1px solid $black;
        }
    }
}

.resume-exp {
    position: relative;
    
    @include tablet {
        width: calc(100% - 320px);
        padding: 0 0 0 30px;
        border-left: 1px solid #ebebeb;
    }

    @include desktop {
        width: calc(100% - 380px);
        padding: 0 0 0 80px;
    }

    li {
        margin: 0 0 20px 0;
        padding: 0 0 30px 0;
        border-bottom: 1px solid #ebebeb;
    }
}

.resume-item-header,
.resume-item-info {
    display: flex;
    
    div {
        min-width: 60px;
        line-height: 1.5;

        @include tablet {
            min-width: 80px;
        }
    }
    
    p, strong {
        line-height: 1.5;
        flex-grow: 1;
        margin: 0;
    }

    .sign {
        min-width: 32px;
        position: relative;
        top: 7px;
    }
}

.resume-item-header {
    margin: 0 0 10px 0;
}