@import '../colors.scss';

svg {
    fill: $black;
}

.icon-wrapper {
    display: inline-block;
    width: 20px;
    height: 20px;
    
    svg {
        display: inline-block;
        width: 100%;
        height: 100%;
        stroke-width: 1px;
    }
}