$micro: 12px;
$small: 14px;
$regular: 16px;
$large: 18px;
$line-height: 1.8;
$line-height-small: 1.4;

$landscape: 480px;
$tablet: 768px;
$desktop: 1024px;
$desktop-plus: 1440px;
$wide: 1600px;
$super: 2048px;

$input-height: 34px;

$shadow: 0 0 6px rgba(0,0,0,0.32);
$unshadow: 0 0 0 rgba(0,0,0,0.32);