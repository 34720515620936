@import './variables.scss';

@mixin flipped {
    @media (orientation: landscape) {
        @content;
    }
}

@mixin landscape {
    @media (min-width: #{$landscape}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

@mixin desktop-plus {
    @media (min-width: #{$desktop-plus}) {
        @content;
    }
}

@mixin wide {
    @media (min-width: #{$wide}) {
        @content;
    }
}

@mixin super {
    @media (min-width: #{$super}) {
        @content;
    }
}
