@import '../media.scss';

.cols {

    & > .col {
        display: inline-block;
        vertical-align: top;
    }

    &.cols-2 {
        & > .col {
            width: 100%;

            @include tablet {
                width: 50%;
            }
        }
    }

    &.cols-3 {
        & > .col {
            width: 100%;
            
            @include tablet {
                width: 33.33%;
            }
        }
    }

    &.cols-4 {
        & > .col {
            width: 100%;
            
            @include tablet {
                width: 25%;
            }
        }
    }

    &.cols-5 {
        & > .col {
            width: 100%;

            @include tablet {
                width: 20%;
            }
        }
    }
}

.cols.fixed {

    &.cols-2 {
        & > .col {
            width: 50%;
        }
    }

    &.cols-3 {
        & > .col {
            width: 33.33%;
        }
    }

    &.cols-4 {
        & > .col {
            width: 25%;
        }
    }

    &.cols-5 {
        & > .col {
            width: 20%;
        }
    }
}