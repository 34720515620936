@import '../variables.scss';
@import '../colors.scss';

$width: 40px;
$bar-height: 2px;

.interaction-text {
    .input-statuses {
        position: relative;
        top: -1px;
        width: calc(100% - #{$width});
    }

    .disabled-bar,
    .success-bar,
    .error-bar,
    .focus-bar {
        transition: opacity 260ms ease;
        transform-origin: top center;
        height: $bar-height;
        width: 100%;
        background-color: $blue-text-dark;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 5;
    }

    .success-bar {
        z-index: 6;
        background-color: $green-bg;
    }

    .error-bar {
        z-index: 7;
        background-color: $red-bg;
    }

    .disabled-bar {
        z-index: 8;
        background-color: $white;
        border-top: 1px dashed $grey-bg;
    }

    .interaction-status-DISABLED,
    .interaction-status-PENDING,
    .interaction-status-SUCCESS,
    .interaction-status-ERROR {
        position: absolute;
        top: 5px;
        left: 0;
        transition: opacity 260ms ease;
        opacity: 0;
        font-weight: 500;
    }
    
    .interaction-status-DISABLED,
    .interaction-status-PENDING,
    .interaction-status-SUCCESS,
    .interaction-status-ERROR,
    .interaction-status-placeholder {
        font-size: $small;
        line-height: $small;
    }
    
    .interaction-status-placeholder {
        padding: 5px 0 2px 0;
    }
    
    .interaction-status-ERROR {
        color: $red-text;
    }
    
    .interaction-status-SUCCESS {
        color: $green-text;
    }

    .interaction-status-DISABLED {
        color: $disabled-text;
    }

    .interaction-status-PENDING {
        color: $disabled-text;
    }

    &.has-focus {
        .focus-bar {
            opacity: 1;
        }
    }

    &.has-success {

        .success-bar {
            opacity: 1;
        }

        .disabled-bar,
        .error-bar {
            opacity: 0;
        }

        .interaction-status-SUCCESS {
            opacity: 1;
        }

        .interaction-status-ERROR,
        .interaction-status-DISABLED,
        .interaction-status-PENDING {
            opacity: 0;
        }

    }

    &.has-error {

        .error-bar {
            opacity: 1;
        }

        .disabled-bar,
        .success-bar {
            opacity: 0;
        }

        .interaction-status-ERROR {
            opacity: 1;
        }

        .interaction-status-SUCCESS,
        .interaction-status-DISABLED,
        .interaction-status-PENDING {
            opacity: 0;
        }
    }

    &.is-disabled {

        .disabled-bar {
            opacity: 1;
        }

        .error-bar,
        .success-bar {
            opacity: 0;
        }

        .interaction-status-DISABLED {
            opacity: 1;
        }
        
        .interaction-status-SUCCESS,
        .interaction-status-PENDING,
        .interaction-status-ERROR {
            opacity: 0;
        }

    }

    &.is-loading {

        .disabled-bar {
            opacity: 1;
        }

        .error-bar,
        .success-bar {
            opacity: 0;
        }

        .interaction-status-PENDING {
            opacity: 1;
        }
        
        .interaction-status-SUCCESS,
        .interaction-status-DISABLED,
        .interaction-status-ERROR {
            opacity: 0;
        }

    }
}