@import '../../ui/media.scss';

.styleguide {
    .col {
        padding: 30px 0;

       @include tablet {
           padding: 30px;
       } 
    }
}