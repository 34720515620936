@import '../colors.scss';
@import '../media.scss';

.checkboxes {

    padding: 6px 0 0 0;

    // label {
    //     position: relative;
    //     display: inline-block;
    //     margin: 0 0 10px 0;
    //     padding: 0 0 0 40px;
    //     width: 100%;
    //     cursor: pointer;

    //     &:hover {
    //         outline: none;

    //         .sign {
    //             background-color: $blue-bg-light;
    //         }
    //     }
    // }



    // label {
    //     border: 2px solid $blue-text-dark;
    //     display: block;
    //     position: relative;
    //     border-radius: 5px;
    //     margin: 0 0 10px 0;
    //     padding: 6px 10px 10px 46px;
    //     min-height: 34px;
    //     line-height: 1.4;
    //     font-size: 14px;

    //     @include tablet {
    //         font-size: 16px;
    //     }
    // }

    // .sign {
    //     box-shadow: none;
    // }

    // .checkbox {
    //     height: 100%;;
    // }
}