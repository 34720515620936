@import './ui/colors.scss';
@import './ui/media.scss';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-color: $white;
}

input,
button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &.disabled {
        cursor: default;
    }
}

dt, dd {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.relative {
    position: relative;
}

.center {
    text-align: center;
}

blockquote,
figure {
    margin: 0;
}

.tablet {
    display: none;

    @include tablet {
        display: initial;
    }
}

.desktop {
    display: none;

    @include desktop {
        display: initial;
    }
}

.up-to-landscape {
    display: initial;

    @include landscape {
        display: none;
    }
}

.up-to-tablet {
    display: initial;

    @include tablet {
        display: none;
    }
}

.up-to-desktop {
    display: initial;

    @include desktop {
        display: none;
    }
}