@import '../colors.scss';
@import '../variables.scss';

$width: 32px;
$height: $width;

@keyframes blink {
    0% {
      opacity: .8;
      transform: translateY(0) scale(0.8);
    }
    20% {
      opacity: 1;
      transform: translateY(-6px) scale(1);
    }
    100% {
      opacity: .8;
      transform: translateY(0) scale(0.8);
    }
}


.sign {
    background-color: $grey-bg-light;
    border-radius: 50%;
    padding: 6px;
    width: $width;
    height: $height;
}

.pending-sign {

    position: relative;

    .sign {
        background-color: $grey-bg;
    }

    span {
        position: absolute;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: $black;
        top: 16px;
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
    }

    .pending-dot-first {
        left: 4px;
    }

    .pending-dot-middle {
        left: 13px;
        animation-delay: .2s;
    }

    .pending-dot-last {
        left: 22px;
        animation-delay: .4s;
    }
}

.disabled-sign {

    .sign {
        background-color: #cfdbe6;
    }
    
    svg {
        fill: $white;
    }
}

.success-sign {

    .sign {
        background-color: $green-bg;
    }
    
    svg {
        fill: $white;
    }
}

.error-sign {

    .sign {
        background-color: $red-bg;
    }
    
    svg {
        fill: $white;
    }
}

.fake-button,
button {

    .sign {
        
        transition: box-shadow 300ms ease 20ms;
        // transition: background-color 300ms ease 20ms;
        background-color: $blue-text-dark;
        background-color: $white;
        // border: 1px solid #d0dae4;

        svg {
            fill: $white;
            fill: #7fa8ff;
        }
    
    }

    &:hover,
    &:active,
    &:focus {
        
        outline: none;

        .sign {
            // background-color: $blue-bg-light;
            // background-color: #e9f0fb;
            // background-color: #f5f5f5;
            box-shadow: $shadow;
        }
    }
}

a {

    & > .sign {
        background-color: #caf5e0;
        box-shadow: $shadow;
    }
}